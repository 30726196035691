import request from '../utils/request'
import config from './config'

/**
 * 角色管理
 */
// 角色管理--列表
export function getRoleList(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/pageList`,
    method: 'get',
    params,
  })
}

// 角色管理--新增/编辑
export function addOrEditRole(data) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/addRole`,
    method: 'post',
    data,
  })
}

// 角色管理--删除
export function deleteRole(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/deleteRole`,
    method: 'get',
    params,
  })
}

// 角色管理--查看
export function viewRole(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/viewRole`,
    method: 'post',
    params,
  })
}

export function getMenuTree(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionApplicationDispose/menuTree`,
    method: 'post',
    data,
  })
}
//设置权限
export function setPermissions(data) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/setPermissions`,
    method: 'post',
    data,
  })
}
